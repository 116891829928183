<template>
    <div class="project">
        <h1>Intel Tunnel</h1>
        <div class="topInfo">
            <p>
                When: 2014<br />
                Agency:
                <a href="http://www.pattenstudio.com/">Patten Studio</a> <br />
                Role: Graphics Programmer
            </p>
        </div>
        <b-img class="media" src="@/assets/projects/intel-tunnel/intel-tunnel1.jpg" fluid />
        <div class="content">
            <p>
                Part of Intel’s booth at CES 2015, we created a 110 foot long interactive video wall comprised of three
                distinct scenes; Ice, Sand, and Space. The wall lived in a tunnel that gave participants an enriching
                and playful experience, isolated from the many overwhelming tech booths common to CES.
            </p>
            <p>
                I was focused on programming the Space scene, one of my favorite mediums. The scene contained animated
                gas clouds, interactive stars that followed people's silhouettes in the form of nebulas, and asteroids
                you could reach out and explode with your hands.
            </p>
        </div>
        <b-embed
            class="media"
            type="iframe"
            aspect="16by9"
            allowfullscreen
            src="https://player.vimeo.com/video/143923636"
        ></b-embed>
        <div class="content">
            <h2>Hardware</h2>
            <p>
                The video wall contained three separate sections that each displayed a 7560x1920 pixel window using
                NVidia Mosaic, running on dual Quadro K5200 graphics cards. For depth capture, we used 14 RealSense
                depth cameras (still pre-release at the time of this project) per section, stacking two on top of each
                other to create a wider vertical field-of-view. The capture feeds were powered by secondary PCs, which
                filtered the depth, compressed and then sent their streams over CAT5 cables to the main display PC.
            </p>
            <h2>Space Scene</h2>
            <p>
                Because we were rendering at 30fps to such a large canvas and there could be dozens of participants
                interacting with each scene at any given moment, the scene is composed of a few assets that could be
                loaded at launch or procedural rendering techniques. The main elements were:
            </p>
            <ul>
                <li>
                    <b>Background gas clouds - </b> A full-screen artist-created image, then modulated at run-time with
                    FBM noise to animate the clouds. This was the only non-interactive component.
                </li>
                <li>
                    <b>Stars - </b> a combination of a static image and a simple particle system with animated point
                    sprites. The animation was done by taking two different star sprites at random and rotating one
                    image against the other in the fragment shader. The interactive particles responded to detected
                    ‘blobs’ that were post-processed from the capture depth buffer. Each star particle chooses a nearby
                    blob, which becomes its attractor, as well as being propelled away from other nearby stars.
                </li>
                <li>
                    <b>Nebula (silhouettes) -</b> The silhouettes were rendered using a GPU particle system using blurry point
                    sprites. The particles are birthed by mapping the depth buffer into the 3D scene and then randomly
                    looking at depth locations to see if there is something present. Initially, we were attempting to
                    animate the particles using a Navier-Stokes fluid simulation, however we hit performance limitations
                    running at the full canvas size and decided to save that for a future project.
                <li>
                    <b>Asteroids - </b> we designed a set of full-sized asteroids in Cinema4D, along with a number of
                    smaller ‘shards’ that were used during an explosion. The asteroids were imported and rendered with
                    normal mapping and two directional lights. Participants could wave their hands and cause an asteroid
                    to explode.
                </li>
                <li>
                    <b>Supernova -</b>
                    When the asteroids explode, they fly off the screen and a supernova light ring emanates across the
                    canvas. This was easily my favorite piece to work on. Each light ring was procedurally rendered on a
                    rotated plane, using noise functions in polar coordinates.
                </li>
            </ul>
            <div class="bottom-images">
                <!-- <b-img class="media" src="@/assets/projects/intel-tunnel/space_explosion_single.jpg" fluid /> -->
                <b-carousel
                    id="carousel-nova"
                    :interval="4000"
                    background="#ababab"
                    img-width="800"
                    img-height="600"
                    fade
                >
                    <b-carousel-slide img-src="@/assets/projects/intel-tunnel/space_explosion_single.jpg" />
                    <b-carousel-slide img-src="@/assets/projects/intel-tunnel/space_explosions_many.jpg" />
                </b-carousel>

                <!-- <b-img class="media" src="@/assets/projects/intel-tunnel/space_explosions_many.jpg" fluid /> -->
            </div>
            <b-img class="media" src="@/assets/projects/intel-tunnel/space_asteroid.gif" fluid />
            <!-- <b-container>
                <b-row align-v="center">
                    <b-col style="padding: 30px 30px; max-width=100px">
                        <b-img src="@/assets/projects/intel-tunnel/space_explosions_many.jpg" />
                    </b-col>
                    <b-col>
                        <b-img src="@/assets/projects/intel-tunnel/space_asteroid.gif" />
                    </b-col>
                </b-row>                
            </b-container> -->
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";

.bottom-images {
    // display: flex;
    justify-content: center;
    padding: 1em 0;
}

</style>